import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { API, graphqlOperation } from 'aws-amplify'
import { Auth } from 'aws-amplify'

import { getAPNAmbassadorProfile } from "./graphql/queries"
import { createAPNAmbassadorProfile } from "./graphql/mutations"
import { updateAPNAmbassadorProfile } from "./graphql/mutations"

import * as JWT from 'jwt-decode';
import '@amzn/awsui-components-react/index.css';
import './styles/form.scss';

import { MultiSelect } from "react-multi-select-component";

import {
    AppLayout,
    Button,
    ColumnLayout,
    DatePicker,
    Form,
    FormField,
    FormSection,
    Input,
    Select,
    Textarea,
    Spinner
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from './common/labels';
import './styles/form.scss';
import { regionOptions, countryOptions, regionCountries, adminRegionOptions } from './CountriesReference'
import { AWSCertificationOptions } from './AWSCertifications'
import { AmbassadorProgramStatusOptions } from './AmbassadorProgramStatusOptions'

const validURL = (str) => {
    var isValidUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
    return isValidUrl.test(str);
}

const validEmail = (str)=>
{
 return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str))
}

var headshotStyle = {
    float: 'right',
    border: '1px solid #ddd',
    'borderRadius': '4px',
    'padding': '5px',
    width: '100px'
};

var redBorderStyle = {
    border: '1px solid #f00'
};
var noBorderStyle = {
    border: '1px solid #fff'
};


var floatRightStyle = {
    float: 'right',
    width: '75px'
};
var floatLeftStyle = {
    float: 'left',
    width: '275px'
};
var removeCert = {
    float: 'right'
};

var certValidationInput = {
    float: 'left',
    width: '320px'
};
export const
    YesNo = [
        {
            "label": "YES",
            "id": "YES"
        },
        {
            "label": "NO",
            "id": "NO"
        }
    ];

export const
    TrueFalse = [
        {
            "label": "YES",
            "id": "true"
        },
        {
            "label": "NO",
            "id": "false"
        }
    ];

export const PartnerTypeOptions =
    [
        {
            "label": "-",
            "id": "-"
        },
        {
            "label": "Services",
            "id": "Services"
        },
        {
            "label": "Software",
            "id": "Software"
        }
    ]

export const
    shirtSizeOptions = [
        {
            "label": "S",
            "id": "S"
        },
        {
            "label": "M",
            "id": "M"
        },
        {
            "label": "L",
            "id": "L"
        },
        {
            "label": "XL",
            "id": "XL"
        },
        {
            "label": "2XL",
            "id": "2XL"
        },
        {
            "label": "3XL",
            "id": "3XL"
        }
    ];

export const superpowersOptions = [
    { value: 'Blogger', label: 'Blogger' },
    { value: 'Conference Speaker', label: 'Conference Speaker' },
    { value: 'Meetup Organizer', label: 'Meetup Organizer' },
    { value: 'Open Source Contributor', label: 'Open Source Contributor' },
    { value: 'Tech Author', label: 'Tech Author' },
    { value: 'Trainer', label: 'Trainer' },
    { value: 'Vlogger', label: 'Vlogger' },
]

export const retirementReasonOptions = [

    {
        "label": "Left the company",
        "id": "Left the company"
    },
    {
        "label": "Moved to another partner",
        "id": "Moved to another partner"
    },
    {
        "label": "Moved to AWS",
        "id": "Moved to AWS"
    },
    {
        "label": "No longer interested",
        "id": " No longer interested"
    },
    {
        "label": "Not meeting requirements",
        "id": "Not meeting requirements"
    },
    {
        "label": "Email bounced",
        "id": "Email bounced"
    },
    {
        "label": "Other",
        "id": "Other"
    },

]

export const programLevelOptions = [

    {
        "label": "Associate",
        "id": "Associate"
    },
    {
        "label": "Ambassador",
        "id": "Ambassador"
    },
    {
        "label": "Principal",
        "id": "Principal"
    }
]


export const personaOptions = [
    {
        "label": "Technical",
        "id": "Technical"
    },
    {
        "label": "Commercial",
        "id": "Commercial"
    }
]

export const ProfileForm = ({ watcher, newAmbassador, certifications, profile, ready, adminMenu, history, current_program_status }) => {

    console.log("ProfileForm");
    console.log(profile);

    if (!profile.persona){
        profile.persona="Technical"
    }

    //const [superpowers, setSuperpowers] = useState(profile.superpowers);

    //  console.log(profile);

    const loggedInProfile = JSON.parse(localStorage.getItem('apn-ambassador-profile'))


    const validation = watcher.state.valid;
    const superpowers = profile.superpowers;
    let program_status_modified = false;

    // console.log(history);
    console.log("New Ambassador: " + newAmbassador);

    if (ready === false) return null;

    function handleChange(e, target) {
        e.preventDefault();
        console.log("Handle change:" + target);
        //console.log(e);
        if (e.detail.value !== undefined) {
            profile[target] = e.detail.value;
            profile[target] = profile[target].trim();
            validate();
        }

        if (target === "email") {
            profile[target] = profile[target].toLowerCase();
        }

        if (target === "executive_sponsor_email") {
            profile[target] = profile[target].toLowerCase();
        }

        if (e.detail.selectedId !== undefined) {
            profile[target] = e.detail.selectedId;

            if (target === "program_status") {
                program_status_modified = true;
                profile.program_status_modified_by = loggedInProfile.email;
                var todayDate = new Date();
                profile.program_status_modified_date = todayDate.toISOString().split('T')[0];


                // do we need to so anything in case of retirement here?

                if (profile.program_status==="RETIRED"){
                    profile.is_disabled = true;
                }

                if (profile.program_status==="SUSPENDED"){
                    profile.suspended_date = todayDate.toISOString().split('T')[0];
                }

            }

            if (target === "country") {
                profile[target] = countryOptions.find(x => x.id === e.detail.selectedId).label;
            }

            if (target === "region") {
                // rebuild the countries select
                // set country to the first item
                profile.region = e.detail.selectedId;


                profile.country = regionCountries(e.detail.selectedId)[0].label;
                watcher.modifyState({ profile: profile });

            }

        }
        validate();
        //console.log(profile);
    }


    var selectedCertification;
    function handleCertChange(e, target) {
        e.preventDefault();
        //  console.log("Handle handleCertChange:" + target);
        //  console.log(e);
        //  console.log(certifications);
        selectedCertification = e.detail.selectedId;
    }

    function onValidationCodeChange(e, target) {
        e.preventDefault();
        //  console.log("Handle onValidationCodeChange:" + target);
        //  console.log(e);
        certifications.find(x => x.exam_code === target).validation_code = e.detail.value;

        console.log(certifications);
    }

    function addCertification(e) {
        e.preventDefault();
        console.log(e);
        // console.log(certifications);

        if (selectedCertification === undefined || selectedCertification === "") { console.log("no selection"); return; };

        if (certifications.find(x => x.exam_code === selectedCertification) !== undefined) { console.log("duplicate"); return; }

        certifications.push({ exam_code: selectedCertification, validation_code: "" });

        console.log(certifications);

        watcher.modifyState({ certifications: certifications });
        watcher.forceUpdate();

    }

    function removeCertification(e, exam_code) {
        e.preventDefault();

        for (var i = 0; i < certifications.length; i++) {
            if (certifications[i].exam_code === exam_code) {
                certifications.splice(i, 1);
            }
        }

        watcher.modifyState({ certifications: certifications });
        watcher.forceUpdate();
        validate();
    }

    function blurHeadshot(e) {
        console.log("blurHeadshot");
        console.log(profile.headshot);
        document.getElementById("headshotThumb").src = profile.headshot;
        validate();
    }

    const isValidCertCode = (code) => {
        if (code === null || code === undefined || (code.length !== 16 && code.length !== 32) || (!code.match("^[A-Z0-9]*$")&&!code.match("^[a-z0-9]*$"))) {
            return false;
        }
        return true;
    }

    const validate = () => {

        // no validation for admins
        //if (adminMenu) {return true;}


        var errors = false;
        validation.first_name = true;
        if (profile.first_name === null || profile.first_name === undefined || profile.first_name === "") {
            validation.first_name = false;
            errors = true;
        }
        validation.last_name = true;
        if (profile.last_name === null || profile.last_name === undefined || profile.last_name === "") {
            validation.last_name = false;
            errors = true;
        }
        validation.title = true;
        if (profile.title === null || profile.title === undefined || profile.title === "") {
            validation.title = false;
            errors = true;
        }
        validation.bio = true;
        if (profile.bio === null || profile.bio === undefined || profile.bio === "") {
            validation.bio = false;
            errors = true;
        }
        /*
        validation.headshot = true;
        if (profile.headshot === null || profile.headshot === undefined || profile.headshot === "") {
            validation.headshot = false;
            errors = true;
        }*/
        
        // validation.certifications = true;
        // if (certifications.length == 0) {
        //     validation.certifications = false;
        //     errors = true;
        // }

        validation.validation_codes = true;
        if (certifications.length > 0) {
            Object.values(certifications).forEach((cert) => {
                console.log(cert);

                if (!isValidCertCode(cert.validation_code)) {
                    validation.validation_codes = false;
                    errors = true;
                }

            })
        }

        validation.superpowers = true;
        if (superpowers.length == 0) {
            validation.superpowers = false;
            errors = true;
        }


        validation.country = true;
        if (profile.country == 'Select') {
            validation.country = false;
            errors = true;
        }

        validation.region = true;
        if (profile.region == 'Select') {
            validation.region = false;
            errors = true;
        }

        validation.member_since=true;

        if (adminMenu  && profile.program_status == "ADMINISTRATOR"){
            if (profile.member_since===null || profile.member_since===undefined || profile.member_since==="") {
                validation.member_since=false;
                errors=true;
            }
        }


        validation.partner_type = true;
        if (adminMenu && profile.program_status !== "AMBASSADOR" && profile.program_status !== "RETIRED" &&
            (profile.partner_type === "Services" || profile.partner_type === "Software")
        ) {
            validation.partner_type = false;
            errors = true;
        } else

            if (adminMenu && profile.program_status == "AMBASSADOR") {
                // admin validation

                validation.partner_name = true;
                if (profile.partner_name === null || profile.partner_name === undefined || profile.partner_name === "" || profile.partner_name === "-") {
                    validation.partner_name = false;
                    errors = true;
                }

                validation.partner_type = true;
                if (profile.partner_type === null || profile.partner_type === undefined || profile.partner_type === "") {
                    validation.partner_type = false;
                    errors = true;
                }

                validation.link_to_nda = true;
                if (profile.link_to_nda === null || profile.link_to_nda === undefined || profile.link_to_nda === "") {
                    validation.link_to_nda = false;
                    errors = true;
                }
                validation.email = true;
                if (profile.email === null || profile.email === undefined || profile.email === "") {
                    validation.email = false;
                    errors = true;
                }

                /*
                validation.psa=true;
                if (profile.psa===null || profile.psa===undefined || profile.psa==="") {
                    validation.psa=false;
                    errors=true;
                }*/
                validation.legal_form = true;
                /*
                if (profile.legal_form === null || profile.legal_form === undefined || profile.legal_form === "") {
                    validation.legal_form = false;
                    errors = true;
                }*/
                
                validation.executive_sponsor=true;
                if (profile.executive_sponsor===null || profile.executive_sponsor===undefined || profile.executive_sponsor==="") {
                    validation.executive_sponsor=false;
                    errors=true;
                }

                validation.executive_sponsor_email=true;
                if (profile.executive_sponsor_email===null || profile.executive_sponsor_email===undefined || profile.executive_sponsor_email==="") {
                    validation.executive_sponsor_email=false;
                    errors=true;
                }

                validation.member_since=true;
                if (profile.member_since===null || profile.member_since===undefined || profile.member_since==="") {
                    validation.member_since=false;
                    errors=true;
                }
              
            }


            if (adminMenu && profile.program_status == "RETIRED") {
                
                validation.retirement_reason=true;
                if (profile.retirement_reason===null || profile.retirement_reason===undefined || profile.retirement_reason==="") {
                    validation.retirement_reason=false;
                    errors=true;
                }

                validation.retirement_date=true;
                if (profile.retirement_date===null || profile.retirement_date===undefined || profile.retirement_date==="") {
                    validation.retirement_date=false;
                    errors=true;
                }

            }



        watcher.modifyState({ valid: validation })
        watcher.forceUpdate();

        //console.log(validation)
        //console.log("validate returning " + !errors);
        return !errors;
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (profile.partner_type === "-") { delete profile['partner_type']; }


        console.log("Handle submit.")
        if (!validate()) return;

        /*
        if (profile.member_since === undefined && adminMenu === true) {
            var todayDate = new Date();
            profile.member_since = todayDate.toISOString().split('T')[0]
        }*/

        // reset the member_since field because this candidate is being promoted.
        /*
        if (profile.program_status == "AMBASSADOR" && (current_program_status == "CANDIDATE" || current_program_status == "IN PROGRESS")) {
            var todayDate = new Date();
            profile.member_since = todayDate.toISOString().split('T')[0]
        }
        */

        if (profile.notes == "") { profile.notes = null }

        profile.superpowers = JSON.stringify(profile.superpowers);


        // Object.keys(profile).forEach((key) => (profile[key] === null || profile[key]==="" ) && delete profile[key]);
        Object.keys(profile).forEach((key) => { if (profile[key] === "") profile[key] = null });

        Object.keys(certifications).forEach((key) => (certifications[key] == null) && delete certifications[key]);


        var outCerts = JSON.stringify(certifications);
        // console.log(outCerts);
        profile.certifications = btoa(outCerts);
        // console.log(profile.certifications);

        if (newAmbassador === true) {
            profile.program_status = "CANDIDATE";

            // not authorized to update these fields 
            if (adminMenu !== true) {
                delete profile['legal_form'];
                delete profile['psa'];
                delete profile['link_to_nda'];
                delete profile['current_usage_code'];
                delete profile['current_usage_code_expiry'];
                delete profile['is_disabled'];
                delete profile['is_admin'];
                delete profile['admin_region'];
                delete profile['public_sector'];
                delete profile['previous_usage_code'];
                delete profile['previous_usage_code_expiry'];
                delete profile['notes'];
                delete profile['partner_type'];
                delete profile['is_reviewer'];
            }

            delete profile['createdAt'];
            delete profile['updatedAt'];
            // create 
            const payload = {
                input: profile
            }
            console.log(JSON.stringify(payload));
            //createProfile(payload); 

            createProfile(payload);
        } else {
            // not authorized to update these fields 
            if (adminMenu !== true) {
                delete profile['program_status'];
                delete profile['legal_form'];
                delete profile['psa'];
                delete profile['link_to_nda'];
                delete profile['current_usage_code'];
                delete profile['current_usage_code_expiry'];
                delete profile['is_disabled'];
                delete profile['is_admin'];
                delete profile['admin_region'];
                delete profile['public_sector'];
                delete profile['previous_usage_code'];
                delete profile['previous_usage_code_expiry'];
                delete profile['notes'];
                delete profile['partner_type'];
                delete profile['is_reviewer'];
            }

            delete profile['createdAt'];
            delete profile['updatedAt'];
            // update  
            const payload = {
                input: profile
            }
            console.log(JSON.stringify(payload));
            //updateProfile(payload); 

            updateProfile(payload);
        }
    }



    const createProfile = async (payload) => {
        await API.graphql(graphqlOperation(createAPNAmbassadorProfile, payload))
            .then(res => {
                if (res.data.createAPNAmbassadorProfile != null) {
                    console.log("ambassador profile successfully created: ", res)
                    history.push({
                        pathname: '/viewProfile/' + profile.cognito_user,
                        state: { flash_message: "Profile successfully updated." }
                    })
                } else {
                    // alert('something went wrong.');
                }
            })
            .catch(error => console.log("Something went wrong updating profile: ", error));
    }

    const updateProfile = async (payload) => {

        /*
        console.log(loggedInProfile);
        if (program_status_modified===true){
            payload.program_status_modified_by=loggedInProfile.email;
            var todayDate = new Date();
            payload.program_status_modified_date.member_since=todayDate.toISOString().split('T')[0];
        }*/

        await API.graphql(graphqlOperation(updateAPNAmbassadorProfile, payload))
            .then(res => {

                if (res.data.updateAPNAmbassadorProfile != null) {
                    console.log("ambassador profile successfully update: ", res)

                    console.log("Current logged cognito_user is: " + watcher.state.currentUser.sub);

                    if (loggedInProfile.cognito_user === profile.cognito_user) {
                        localStorage.setItem('apn-ambassador-profile', JSON.stringify(res.data.updateAPNAmbassadorProfile))

                    }


                    history.push({
                        pathname: '/viewProfile/' + profile.cognito_user,
                        state: { flash_message: "Profile successfully updated." }
                    })
                } else {
                    //alert('something went wrong.');
                }

            })
            .catch(error => console.log("Something went wrong updating profile: ", error));
    }

    /*
    var member_since = profile.member_since;
    if (!member_since) {

        var todayDate = new Date();
        member_since = todayDate.toISOString().split('T')[0]

        if (adminMenu === true) {
            profile.member_since = member_since;
        }

    }*/


    var retirement_date = profile.retirement_date;
    if (!retirement_date && profile.program_status === 'RETIRED') {

        var todayDate = new Date();
        retirement_date = todayDate.toISOString().split('T')[0]

        if (adminMenu) {
            profile.retirement_date = retirement_date;
        }

    }


    console.log("validation.member_since " +  validation.member_since)
    return (
        <div>

            {(ready === true) ?
                <Form
                    header="Profile Details"
                    actions={<div>
                        <Link to={"/viewProfile/" + profile.cognito_user}><Button text='Cancel' /></Link>&nbsp;

                        <Button text="Save" variant="primary" onClick={(e) => handleSubmit(e)}></Button>
                    </div>}
                >

                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root="true">

                            <div>

                                <FormSection header="Enter Ambassador Details">
                                    <FormField label="First Name" ariaRequired={true}>
                                        <Input name="first_name" value={profile.first_name} invalid={validation.first_name !== true} onChange={(e) => { handleChange(e, "first_name") }} ></Input>
                                    </FormField>
                                    <p />
                                    <FormField label="Last Name" ariaRequired={true}>
                                        <Input name="last_name" value={profile.last_name} invalid={validation.last_name !== true} onChange={(e) => { handleChange(e, "last_name") }} ></Input>
                                    </FormField>

                                    <p />
                                    <FormField label="Job Title" ariaRequired={true}>
                                        <Input name="title" value={profile.title} invalid={validation.title !== true} onChange={(e) => { handleChange(e, "title") }} ></Input>
                                    </FormField>
                                    <p />
                                    <FormField label="Region" ariaRequired={true}>
                                        <Select name="region" options={regionOptions} selectedId={profile.region} invalid={validation.region != true} onChange={(e) => { handleChange(e, "region") }}>
                                        </Select>
                                    </FormField>
                                    <p />


                                    <FormField label="Country" ariaRequired={true}>
                                        <Select name="country" options={regionCountries(profile.region)} selectedId={regionCountries(profile.region).find(x => x.label === profile.country).id} invalid={validation.country != true} onChange={(e) => { handleChange(e, "country") }} ></Select>
                                    </FormField>

                                    <p />
                                    <FormField label="Shirt Size" ariaRequired={true}>
                                        <Select name="shirt_size" options={shirtSizeOptions} selectedId={profile.shirt_size} onChange={(e) => { handleChange(e, "shirt_size") }} ></Select>
                                    </FormField>
                                    <p />
                                    <FormField label='BIO' description="Please write your BIO in the third person." >
                                        <Textarea ariaRequired={true} name="bio" value={profile.bio} invalid={validation.bio !== true} onChange={(e) => { handleChange(e, "bio") }} />
                                    </FormField>
                                    <p />
                                    {/*
                                    <div>
                                        {!profile.headshot ?
                                            <img id="headshotThumb" src="/Portrait_Placeholder.png" style={headshotStyle} alt=""></img> :
                                            <img id="headshotThumb" src={profile.headshot} style={headshotStyle} alt=""></img>
                                        }
                                    </div>
                                    <FormField label="Profile Picture URL" ariaRequired={true}>
                                        <Input name="headshot" value={profile.headshot} invalid={validation.headshot !== true} onChange={(e) => { handleChange(e, "headshot") }} onBlur={(e) => { blurHeadshot(e) }} ></Input>
                                    </FormField>

                                    */}
                                    <p />
                                    <FormField label="LinkedIn URL" ariaRequired={true}>
                                        <Input name="linkedin" value={profile.linkedin} onChange={(e) => { handleChange(e, "linkedin") }} ></Input>
                                    </FormField>
                                    <p />
                                    <FormField label="X (Twitter) Handle" ariaRequired={true}>
                                        <Input name="twitter" value={profile.twitter} onChange={(e) => { handleChange(e, "twitter") }} ></Input>
                                    </FormField>
                                    <p />
                                </FormSection>


                            </div>




                            <div>

                                <FormSection header="Additional Information">
                                    <FormField label="Community Strength" ariaRequired={true} description="Choose a minimum of 1 and maximum of 2.">



                                        <div style={(validation.superpowers == false) ? redBorderStyle : noBorderStyle}>
                                            <MultiSelect

                                                options={superpowersOptions}
                                                value={profile.superpowers}
                                                onChange={(v) => {
                                                    console.log(v); console.log(v.length);
                                                    if (v.length < 3) { profile.superpowers = v; }
                                                    if (v.length === 0) { validation.superpowers = false } else { validation.superpowers = true }
                                                    watcher.forceUpdate();
                                                }}
                                                labelledBy={"Select"}
                                                hasSelectAll={false}
                                                disableSearch={true}
                                            />
                                        </div>


                                    </FormField>
                                </FormSection>

                                <FormSection header="Certification Details">

                                    <FormField label="Add Certification" description="Please enter the validation code found at the bottom right of the AWS certificate. Each code is 16 or 32 characters long and consists of letters and numbers only.">
                                        <div style={floatLeftStyle}><Select name="selectedCertification" id="selectedCertification" options={AWSCertificationOptions} selectedId={selectedCertification} onChange={(e) => { handleCertChange(e, "selectedCertification") }} ></Select></div>
                                        <div style={floatRightStyle}><Button variant="normal" onClick={(e) => addCertification(e)} text='Add' /></div>
                                    </FormField>
                                    <p />


                                    {
                                        certifications.map((cert, i) => {
                                            return (
                                                <div>
                                                    <FormField label={AWSCertificationOptions.find(x => x.id === cert.exam_code).label} ariaRequired={true}>
                                                        <div style={certValidationInput}><Input name={cert.exam_code} value={cert.validation_code} invalid={!isValidCertCode(cert.validation_code)} onChange={(e) => { onValidationCodeChange(e, cert.exam_code) }} ></Input></div>
                                                        <div style={{ 'float': 'right' }}><Button variant="icon" icon="status-negative" onClick={(e) => { removeCertification(e, cert.exam_code) }}></Button></div>
                                                    </FormField>
                                                    <p />
                                                </div>
                                            )
                                        })
                                    }


                                </FormSection>


                            </div>

                        </div>
                    </ColumnLayout>



                    {(adminMenu !== true || loggedInProfile.is_reviewer == true) ?
                        null
                        :
                        <ColumnLayout columns={2}>
                            <div data-awsui-column-layout-root="true">

                                <div>

                                    <FormSection header="Administration Details">

                                        <FormField label="Partner Name" ariaRequired={true}>
                                            <Input name="partner_name" value={profile.partner_name} invalid={validation.partner_name !== true} onChange={(e) => { handleChange(e, "partner_name") }} ></Input>
                                        </FormField>
                                        <p />
                                        <FormField label="Partner Type" ariaRequired={true}>
                                            <Select name="partner_type" options={PartnerTypeOptions} invalid={validation.partner_type !== true} selectedId={!profile.partner_type ? "" : profile.partner_type} onChange={(e) => { handleChange(e, "partner_type") }}></Select>
                                        </FormField>
                                        <p />
                                        <FormField label="SPMS ID" description="AWS Internal: SPMS ID - Salesforce." ariaRequired={true}>
                                            <Input name="link_to_nda" value={profile.link_to_nda} invalid={validation.link_to_nda !== true} onChange={(e) => { handleChange(e, "link_to_nda") }} ></Input>
                                        </FormField>
                                        <p />
                                        <FormField label="Email" ariaRequired={true} >
                                            <Input name="email" value={profile.email} invalid={validation.email !== true} onChange={(e) => { handleChange(e, "email") }} ></Input>
                                        </FormField>
                                        <p />

                                        <FormField label="Executive Sponsor"  ariaRequired={true}>
                                            <Input name="executive_sponsor" value={profile.executive_sponsor} invalid={validation.executive_sponsor !== true} onChange={(e) => { handleChange(e, "executive_sponsor") }} ></Input>
                                        </FormField>
                                        <p />

                                        <FormField label="Executive Sponsor Email"  ariaRequired={true}>
                                            <Input name="executive_sponsor_email" value={profile.executive_sponsor_email} invalid={validation.executive_sponsor_email !== true} onChange={(e) => { handleChange(e, "executive_sponsor_email") }} ></Input>
                                        </FormField>
                                        <p />

                                        <FormField label="Program Status" description="Have the ambassador program requirements been met by this candidate?" ariaRequired={true}>
                                            <Select name="shirt_size" options={AmbassadorProgramStatusOptions} selectedId={profile.program_status} onChange={(e) => { handleChange(e, "program_status") }} ></Select>
                                        </FormField>
                                        <p />

                                        <FormField label="Program Level" ariaRequired={true}>
                                            <Select name="program_level" options={programLevelOptions} selectedId={!profile.program_level ? "" : profile.program_level} onChange={(e) => { handleChange(e, "program_level") }}></Select>
                                        </FormField>
                                        <p />

                                        <p />
                                        <FormField label="Persona" ariaRequired={true}>
                                            <Select name="persona" options={personaOptions} selectedId={!profile.persona ? "Technical" : profile.persona} onChange={(e) => { handleChange(e, "persona") }}></Select>
                                        </FormField>
                                        <p />

                                        <p />
                                        <FormField label="Auto Approve Contributions" description="If set to true, this ambassador's contributions will be queued for batch auto-approval." ariaRequired={true}>
                                            <Select name="auto_approve_contributions" options={TrueFalse} selectedId={!profile.auto_approve_contributions ? "false" : profile.auto_approve_contributions} onChange={(e) => { handleChange(e, "auto_approve_contributions") }}></Select>
                                        </FormField>
                                        <p />

                                        <FormField label="Member Since" description="Membership start date?">

                                            <DatePicker
                                                name="member_since"
                                                placeholder="YYYY/MM/DD"
                                                todayLabel="Today"
                                                nextMonthLabel="Next month"
                                                previousMonthLabel="Previous month"
                                                ariaRequired={true}
                                                invalid={validation.member_since !== true}
                                                value={!profile.member_since ? "" : profile.member_since}
                                                onChange={(e) => { handleChange(e, "member_since") }}
                                            ></DatePicker>
                                        </FormField>
                                        <p />
                                        {/*
                                        <FormField label="Legal Form" description="AWS Internal: Is legal satisfied?" ariaRequired={true}>
                                            <Select name="legal_form" options={YesNo} invalid={validation.legal_form !== true} selectedId={!profile.legal_form ? "" : profile.legal_form.toUpperCase()} onChange={(e) => { handleChange(e, "legal_form") }}></Select>
                                        </FormField>
                                        */}
                                        <p />

                                        <FormField label="Credit Code" ariaRequired={true}>
                                            <Input name="current_usage_code" value={profile.current_usage_code} onChange={(e) => { handleChange(e, "current_usage_code") }} ></Input>
                                        </FormField>
                                        <p />
                                        <FormField label="Credit Code Expiry" >

                                            <DatePicker
                                                name="current_usage_code_expiry"
                                                placeholder="YYYY/MM/DD"
                                                todayLabel="Today"
                                                nextMonthLabel="Next month"
                                                previousMonthLabel="Previous month"
                                                ariaRequired={true}
                                                invalid={false}
                                                value={!profile.current_usage_code_expiry ? "" : profile.current_usage_code_expiry}
                                                onChange={(e) => { handleChange(e, "current_usage_code_expiry") }}
                                            ></DatePicker>
                                        </FormField>
                                        <p />
                                        {/** 
            <FormField label="Previous Credit Code" ariaRequired={true}>
                <Input name="previous_usage_code" value={profile.previous_usage_code}  onChange={(e) => {handleChange(e, "previous_usage_code")}} ></Input>
            </FormField>
            <p/>
        <FormField label="Previous Credit Code Expiry" >

        <DatePicker
                name="current_usage_code_expiry"
                placeholder="YYYY/MM/DD"
                todayLabel="Today"
                nextMonthLabel="Next month"
                previousMonthLabel="Previous month"
                ariaRequired={true}
                invalid={false}
                value={!profile.previous_usage_code_expiry? "":profile.previous_usage_code_expiry}
                onChange={(e) => {handleChange(e, "previous_usage_code_expiry")}}
            ></DatePicker>
        </FormField>
            <p />*/}

                                        {/*}
            <p />
            <FormField label="AWS PSA" description="AWS Internal: Name of Partner Solutions Architect." ariaRequired={true}>
                <Input name="psa" value={profile.psa} invalid={validation.psa!==true} onChange={(e) => {handleChange(e, "psa")}} ></Input>
       </FormField>
            <p />
            <FormField label="Public Sector" ariaRequired={true} description="Is this a public sector profile?">
            <Select name="public_sector" options={YesNo} invalid={validation.public_sector!==true}  selectedId={!profile.public_sector ? "" :profile.public_sector.toUpperCase()} onChange={(e) => {handleChange(e, "public_sector")}}></Select>
            </FormField>*/}





                                        <p />
 

                                        <p />
                                    </FormSection>
                                </div>



                                <div>

                                    <FormSection header="Application Administration">

                                        <FormField label="Application Administrator" description="AWS Internal: Is admin of this application?" ariaRequired={true}>
                                            <Select name="is_admin" options={TrueFalse} selectedId={!profile.is_admin ? "false" : profile.is_admin.toString()} onChange={(e) => { handleChange(e, "is_admin") }}></Select>
                                        </FormField>
                                        <p />
                                        <FormField label="Review Only" description="Limit this user to reviewing contributions only." ariaRequired={true}>
                                            <Select name="is_reviewer" options={TrueFalse} selectedId={!profile.is_reviewer ? "false" : profile.is_reviewer.toString()} onChange={(e) => { handleChange(e, "is_reviewer") }}></Select>
                                        </FormField>
                                        <p />
                                        <FormField label="Administration Region" ariaRequired={true}>

                                            {(loggedInProfile.admin_region !== null && loggedInProfile.admin_region !== "GLOBAL") ?
                                                <Select name="admin_region" disabled={true} options={adminRegionOptions} selectedId={loggedInProfile.admin_region} onChange={(e) => { handleChange(e, "admin_region") }}>
                                                </Select>
                                                :
                                                <Select name="admin_region" options={adminRegionOptions} selectedId={profile.admin_region !== null ? profile.admin_region : 'GLOBAL'} onChange={(e) => { handleChange(e, "admin_region") }}>
                                                </Select>
                                            }


                                        </FormField>
                                        <p />
                                        <FormField label="Is disabled" description="Is this account disabled ? User will not be able to login." ariaRequired={true}>
                                            <Select name="is_admin" options={TrueFalse} selectedId={!profile.is_disabled ? "false" : profile.is_disabled.toString()} onChange={(e) => { handleChange(e, "is_disabled") }}></Select>
                                        </FormField>
                                        <p />



                                    </FormSection>


                                    <FormSection header="Additional Information">
                                        <FormField label="Notes" ariaRequired={true}>
                                            <Textarea name="notes" value={profile.notes} placeholder="" onChange={(e) => { handleChange(e, "notes") }}></Textarea>
                                        </FormField>
                                        <p />

                                        {(profile.program_status === 'RETIRED') ?
                                        <>
                                        <FormField label="Retirement Reason" ariaRequired={true}>
                                        <Select name="retirement_reason" options={retirementReasonOptions} invalid={validation.retirement_reason !== true} selectedId={profile.retirement_reason} onChange={(e) => { handleChange(e, "retirement_reason") }}>
                                        </Select>
                                        </FormField>
                                            <p/>
                                        <FormField label="Retirement Date">

                                        <DatePicker
                                            name="retirement_date"
                                            placeholder="YYYY/MM/DD"
                                            todayLabel="Today"
                                            nextMonthLabel="Next month"
                                            previousMonthLabel="Previous month"
                                            ariaRequired={true}
                                            invalid={false}
                                            value={retirement_date}
                                            onChange={(e) => { handleChange(e, "retirement_date") }}
                                        ></DatePicker>
                                        </FormField>
                                        <p/>
                                        </>
                                        :
                                        null
                                    }

                         
                                    </FormSection>


                                </div>

                            </div>
                        </ColumnLayout>

                    }


                </Form>

                :
                <Spinner></Spinner>
            }


        </div>

    );
};

class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current_program_status: "",
            profile: {
                "cognito_user": this.props.match.params.id,
                first_name: "", last_name: "",
                country: "Select",
                region: "Select", partner_name: "", current_usage_code: "", current_usage_code_expiry: "", previous_usage_code: "", previous_usage_code_expiry: "", psa: "", link_to_nda: "", legal_form: "", shirt_size: "M", is_admin: false, is_reviewer: false, admin_region: "NA", superpowers: []
            },
            certifications: [],
            flash_message: null,
            ready: true,
            isAdministrator: false,
            newAmbassador: false,
            valid: {
                first_name: true,
                last_name: true,
                title: true,
                partner_name: true,
                bio: true,
                headshot: true,
                certifications: true,
                region: true,
                country: true,
                partner_type: true,
                link_to_nda: true,
                email: true,
                current_usage_code: true,
                current_usage_code_expiry: true,
                previous_usage_code: true,
                previous_usage_code_expiry: true,
                psa: true,
                public_sector: true,
                legal_form: true,
                executive_sponsor: true,
                executive_sponsor_email: true,
                retirement_reason: true,
                member_since:true
            }
        }
    }


    componentDidMount() {
        // console.log("will mount " +myDecipher(this.props.match.params.id));

        let parent = this;

        Auth.currentAuthenticatedUser()
            .then(user => {
                console.log(user);
                var token = JWT(user.signInUserSession.accessToken.jwtToken);
                var isAdministrator = false;
                if (token['cognito:groups'] !== undefined && token['cognito:groups'][0] === "APNAmbassadorsAdministrators") { isAdministrator = true; }
                user.attributes.administrator = isAdministrator;
                var profile = this.state.profile;
                profile.email = user.attributes.email;
                parent.setState({ currentUser: user.attributes, profile: profile, isAdministrator: isAdministrator });

                this.getProfileForUser(this.props.match.params.id);

            }).catch(err => console.log(err)) // end authenticationn


    } // end will mount

    getProfileForUser(id) {

        console.log("getProfileForUser " + id);
        // fetch contributions for this user
        API.graphql(graphqlOperation(getAPNAmbassadorProfile, { "cognito_user": id }))
            .then(res => {
                console.log("Ambassador res ", res)

                var theseCertifications = [];


                if (res.data.getAPNAmbassadorProfile !== null) {
                    // parse certifications
                    if (
                        res.data.getAPNAmbassadorProfile !== null &&
                        res.data.getAPNAmbassadorProfile.certifications !== undefined &&
                        res.data.getAPNAmbassadorProfile.certifications !== "" &&
                        res.data.getAPNAmbassadorProfile.certifications !== null) {
                        theseCertifications = JSON.parse(atob(res.data.getAPNAmbassadorProfile.certifications));
                    }
                    console.log(res.data.getAPNAmbassadorProfile);
                    if (res.data.getAPNAmbassadorProfile.superpowers !== undefined && res.data.getAPNAmbassadorProfile.superpowers !== null && res.data.getAPNAmbassadorProfile.superpowers !== "null") {
                        console.log(res.data.getAPNAmbassadorProfile.superpowers);
                        res.data.getAPNAmbassadorProfile.superpowers = JSON.parse(res.data.getAPNAmbassadorProfile.superpowers);
                    } else { res.data.getAPNAmbassadorProfile.superpowers = []; }

                    this.setState({ profile: res.data.getAPNAmbassadorProfile, ready: true, certifications: theseCertifications, current_program_status: res.data.getAPNAmbassadorProfile.program_status, persona: "Technical" })
                } else {
                    this.setState({ newAmbassador: true, ready: true, certifications: theseCertifications })
                }


            }).catch(error => {
                console.log("Couldn't establish ambassador ", error);
                this.setState({ error: "Unable to load profile.", ready: true, newAmbassador: true })
            });

    }

    modifyState(state) {
        this.setState(state);
        this.forceUpdate();
    }

    render() {

        // console.log(this.props);

        return (
            <div class='awsui'>



                <AppLayout
                    contentType='form'
                    content={<ProfileForm watcher=
                        {this} newAmbassador={this.state.newAmbassador} certifications={this.state.certifications} profile={this.state.profile} current_program_status={this.state.current_program_status} ready={this.state.ready} adminMenu={this.state.isAdministrator} history={this.props.history} />
                    }
                    navigationHide
                    toolsHide
                    labels={appLayoutLabels}
                />

            </div>

        );
    }

}

export default EditProfile;
