  export const yearOptions =   [
    {
      label: "ALL",
      id: "ALL",
    },
    {
        label: "2019",
        id: "2019",
    },
    {
      label: "2020",
      id: "2020",
  },
  {
    label: "2021",
    id: "2021",
  },
  {
    label: "2022",
    id: "2022",
  },
  {
    label: "2023",
    id: "2023",
  },
  {
    label: "2024",
    id: "2024",
  },
  {
    label: "2025",
    id: "2025",
  }
];

